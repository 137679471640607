<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" name="organizationId" label="影院组织">
						<a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 180px;" @change="(id) => getAllCinemaList(id, false)">
							<!-- <a-select-option :value="0">全部</a-select-option> -->
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item class="ui-form__item" name="cinemaId" label="所属影院">
						<a-select placeholder="请选择" v-model:value="formState.id" style="width: 180px;">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item class="ui-form__item" name="entityCardNo" label="卡号">
						<a-input v-model:value="formState.entityCardNo" placeholder="请输入卡号"></a-input>
					</a-form-item>
				</a-row>

				<a-row>
                    <a-col :span="24" style="text-align: right">
					<a-button
						style="margin-right: 10px"
						type="primary"
						html-type="submit"
						>搜索</a-button
					>
					<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table :pagination="pagination" :columns="columns" 
					rowKey="id"
					:dataSource="list"
				>
					<template #bodyCell="{ column, record}">
						<template v-if="column.key === 'time'">
							{{ transDateTime(record.startTime, 1) }} ~ {{ transDateTime(record.endTime, 1) }}
						</template>
						<template v-if="column.key === 'updateTime'">
							{{ transDateTime(record.updateTime) }}
						</template>
						<template v-if="column.key === 'deductCardVolumeTitle'">
							{{ record.deductCardVolumeTitle?record.deductCardVolumeTitle:'----'}}
						</template>
						<template v-if="column.key === 'cardVolumeTitle'">
							{{ record.cardVolumeTitle?record.cardVolumeTitle:'----'}}
						</template>
						<template v-if="column.key === 'entityCardNo'">
							{{ record.entityCardNo }}
						</template>
						<template v-if="column.key === 'goodsDiscount'">
							{{ record.goodsDiscount }}%
						</template>
						<template v-if="column.key === 'currentCardMode'">
							{{ record.currentCardMode===1?'线上会员卡':record.currentCardMode===2?'影城会员卡':'不展示' }}
						</template>
						<template v-if="column.key === 'deductCurrentCardMode'">
							{{ record.deductCurrentCardMode===1?'影城会员卡等级':'影城会员卡折扣'}}
						</template>
						<template v-if="column.key === 'action'">
							<a-button v-permission="['ciname_policy_defaultMemberCard_edit']" type="link" size="small" @click="onEdit(record)">编辑</a-button>
						</template>
					</template>
				</a-table>
			</div>
			
		</a-spin>
		
		<a-modal v-model:visible="showModal" title="编辑" width="800px">
			<template #footer>
				<a-button @click="showModal = false">取消</a-button>
				<a-button type="primary" @click="onSubmit">提交</a-button>
				<!-- <a-button type="primary" @click="onSubmit(false)">添加并继续</a-button> -->
			</template>
			<a-spin :spinning="loading">
				<a-form ref="addForm" scrollToFirstError :model="modelRef" name="addForm" :labelCol="{ span:6 }">
					<div>
						<a-form-item label="卡扣费方式" name="deductCurrentCardMode" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<a-select v-model:value="modelRef.deductCurrentCardMode" placeholder="请选择">
								<a-select-option :value="2">实体卡模式</a-select-option>
								<a-select-option :value="1">线上等级抵扣模式</a-select-option>
							</a-select>
						</a-form-item>
						
						<div>
							<a-form-item label="卡卖品折扣" name="goodsDiscount" :rules="[{ required: true, message: '必填项不允许为空' }]" extra='100%则卖品无优惠折扣'>
								<a-input-number v-model:value="modelRef.goodsDiscount" :min="0" :max="100" :precision="2"></a-input-number> %
							</a-form-item>
						</div>
						<div v-if="modelRef.deductCurrentCardMode === 1">
							<a-form-item label="线上扣款卡等级" name="deductCardVolumeId" :rules="[{ required: true, message: '必填项不允许为空' }]">
								<a-select v-model:value="modelRef.deductCardVolumeId" placeholder="请选择" style="width: 300px;">
									<a-select-option v-for="item in storedLvList" :key="item.id" :value="item.id">{{ item.title }}</a-select-option>
								</a-select>
								<a-button style="margin-left: 10px;" type="primary" shape="circle" @click="getCardPolicyList(modelRef.id)">
								    <template #icon>
								        <Icon icon="RedoOutlined"></Icon>
								    </template>
								</a-button>
							</a-form-item>
						</div>
						
						<a-form-item label="排期列表会员价展示模式" name="currentCardMode" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<a-select v-model:value="modelRef.currentCardMode" placeholder="请选择">
								<a-select-option :value="0">不展示</a-select-option>
								<a-select-option :value="1">线上会员卡</a-select-option>
								<a-select-option :value="2">实体卡</a-select-option>
							</a-select>
						</a-form-item>
						
						<div v-if="modelRef.currentCardMode === 1">
							<a-form-item label="排期列表价格展示卡等级" name="cardVolumeId" :rules="[{ required: true, message: '必填项不允许为空' }]">
								<a-select v-model:value="modelRef.cardVolumeId" placeholder="请选择" style="width: 300px;">
									<a-select-option v-for="item in storedLvList" :key="item.id" :value="item.id">{{ item.title }}</a-select-option>
								</a-select>
								<a-button style="margin-left: 10px;" type="primary" shape="circle" @click="getCardPolicyList(modelRef.id)">
								    <template #icon>
								        <Icon icon="RedoOutlined"></Icon>
								    </template>
								</a-button>
							</a-form-item>
						</div>
						<div v-if="modelRef.currentCardMode === 2">
							<a-form-item label="实体卡卡号" name="entityCardNo" :rules="[{ required: true, message: '必填项不允许为空' }]">
								<a-input v-model:value="modelRef.entityCardNo" placeholder="请输入影城会员卡卡号"></a-input>
							</a-form-item>
						</div>
					</div>
				</a-form>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import { getOrganizationList, getCinemaList,getCinemaDetail,updateCinema } from '@/service/modules/cinema.js';
	import {getCardPolicyList,getCardPolicyDetail} from '@/service/modules/coupon.js'
	export default {
		components: {
			Icon,
		},
		data() {
			return {
				loading: false,
				organizationList: [],
				cinemaAllList: [],
				cinemaListFormAdd: [],
				formState: {
					// organizationId: 0,
					id: 0,
				},
				searchData: {},
				showModal: false,
				modelRef: {},
				selectedRowKeys: [],
				selectedListData: [],
				list: [],
				storedLvList:[],
				columns: [{
					title: '影院',
					dataIndex: 'name'
				}, {
					title: '卡扣费方式',
					key: 'deductCurrentCardMode'
				}, {
					title: '线上扣款等级',
					key: 'deductCardVolumeTitle'
				}, {
					title: '实体卡模式卖品折扣',
					key: 'goodsDiscount'
				}, {
					title: '排期价格展示模式',
					key:'currentCardMode'
				}, {
					title: '实体卡号',
					key: 'entityCardNo'
				}, {
					title: '线上会员卡等级',
					key: 'cardVolumeTitle'
				}, {
					title: '更新时间',
					key: 'updateTime'
				}, {
					title: '操作',
					key: 'action',
					width: 100,
					fixed: 'right'
				}],
				storedColumns: [{
                    title: '等级名称',
                    dataIndex: 'title'
                },{
                    title: '是否启用',
                    key: 'isDisabled',
                    width: 100
                }],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				storedPagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.storedPagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.storedPagination.current = 1;
						this.storedPagination.pageSize = pageSize;
						this.getData();
					}
				},
				isEdit: false,
				id: 0,
				isSelect:0
			}
		},
		created() {
			this.getOrganizationList();
		},
		methods: {
			onBack() {
				this.showModal = false;
			},
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.organizationId = this.searchData.organizationId ? this.searchData.organizationId : undefined;
				this.searchData.id = this.searchData.id ? this.searchData.id : undefined;
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				if (this.organizationList.length) {
					this.formState.organizationId = this.organizationList[0].id;
					this.getAllCinemaList(this.formState.organizationId);
				}
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getCinemaList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.list = ret.data.list;
						this.modelRef = ret.data;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					if (ret.code === 200) {
						this.organizationList = ret.data.list;
						if (this.organizationList.length) {
							this.formState.organizationId = this.organizationList[0].id;
							this.getAllCinemaList(this.formState.organizationId);
						}
						this.getData();
					} else {
						this.loading = false;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getAllCinemaList(organizationId, isAdd) {
				if (isAdd) {
					this.cinemaListFormAdd = [];
					this.modelRef.id = undefined;
				} else {
					this.cinemaAllList = [];
					this.formState.id = 0;
				}
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					if (isAdd) {
						this.cinemaListFormAdd = ret.data.list;
					} else {
						this.cinemaAllList = ret.data.list;
					}
				}
			},
			async onSubmit() {
				this.$refs.addForm.validateFields().then(async () => {
					try {
						let postData = JSON.parse(JSON.stringify(this.modelRef));
						this.loading = true;
						let ret = await updateCinema(postData);
						if (ret.code === 200) {
							this.$message.success("操作成功");
							this.showModal = false;
							this.getData();
						}
					} catch (e) {
						this.loading = false;
					}
				});
			},
			onEdit(record) {
				this.getCardPolicyList(record.id);
				this.getCinemaDetailCommon(record);
			},
		    async onSelectChange(record, selected) {
				if (this.type === 'checkbox') {
					if (selected) {
						this.selectedListData.push(JSON.parse(JSON.stringify(record)));
						this.selectedRowKeys.push(record.id);
					} else {
						this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
						this.selectedListData = this.selectedListData.filter(item => {
							return item.id !== record.id;
						});
					}
				} else {
					if (selected) {
						if(this.isSelect===1){
							this.modelRef.deductCardVolumeId = record.id
						}else if(this.isSelect===2){
							this.modelRef.cardVolumeId = record.id
						}
						this.selectedListData = [JSON.parse(JSON.stringify(record))];
						this.selectedRowKeys = [record.id];
					} else {
						this.selectedListData = [JSON.parse(JSON.stringify(record))];
						this.selectedRowKeys = [];
					}
				}
			},
			onToggle(item) {
				this.getCinemaDetailCommon(item)
				this.$confirm({
					title: "提示",
					content:
					"确定" + (item.isToggle === 1 ? "启用线上会员" : "启用影城会员") + "卡吗？",
					onOk: async () => {
						let postData = JSON.parse(JSON.stringify(this.modelRef));
							postData.isVisionTips = postData.isVisionTips ? 1 : 0;
							postData.mustAddService = postData.mustAddService ? 1 : 0;
							if(item.isToggle===1){
								postData.currentCardMode = 1
							}else{
								postData.currentCardMode = 2
							}
						try {
						let ret = await updateCinema(postData);
						this.loading = true;
						if (ret.code === 200) {
							this.$message.success("操作成功");
							this.showModal = false;
							this.getData();
						}
						} catch (e) {
						this.loading = false;
						}
				},
			});
			},
		   async getCinemaDetailCommon(record){
				this.loading = true;
					try {
						let ret = await getCinemaDetail({
						id: record.id,
					});
					this.loading = false;
					if (ret.code === 200) {
						ret.data.isVisionTips = ret.data.isVisionTips ? true : false;
						ret.data.mustAddService = ret.data.mustAddService ? true : false;
						ret.data.coordinate = ret.data.ycoordinate + "," + ret.data.xcoordinate;
						this.modelRef = ret.data;
						this.showModal = true;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			async getCardPolicyList(id){
				this.loading = true;
				try {
					let ret = await getCardPolicyList({
						page: 1,
						pageSize: 99999,
						useCinemaId: id,
						isDisabled: 0,
						type: 3
					})
					this.loading = false;
					if (ret.code === 200) {
						this.storedLvList = ret.data.list;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			// 影城会员卡扣费方式
		   	onToggleDeduct(item){
				this.getCinemaDetailCommon(item)
				this.$confirm({
					title: "提示",
					content:
					"确定切换为" + (item.deductCurrentCardMode === 1 ? "影城会员卡折扣" : "影城会员卡等级") + "吗？",
					onOk: async () => {
						let postData = JSON.parse(JSON.stringify(this.modelRef));
							postData.isVisionTips = postData.isVisionTips ? 1 : 0;
							postData.mustAddService = postData.mustAddService ? 1 : 0;
							if(item.deductCurrentCardMode===1){
								postData.deductCurrentCardMode = 2
							}else{
								postData.deductCurrentCardMode = 1
							}
						try {
						let ret = await updateCinema(postData);
						this.loading = true;
						if (ret.code === 200) {
							this.$message.success("操作成功");
							this.showModal = false;
							this.getData();
						}
						} catch (e) {
						this.loading = false;
						}
				}
			});
			}
		}
	}
</script>

<style>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
